import React, { Component } from 'react'
import Toggle from 'react-toggle'
import { slugify } from '../helpers/UIHelpers'

import getFirebase from '../firebase/firebase'

class MyMemorialSettings extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isPreApprovalRequired: false,
      isPrivate: false,
      personName: '',
    }
    this.handleMessagesPreApproval = this.handleMessagesPreApproval.bind(this)
    this.handlePublicPrivateMemorial = this.handlePublicPrivateMemorial.bind(this)
  }

  componentDidMount() {
    const { memorialId } = this.props
    const { firestore } = getFirebase()
    firestore
      .collection('Memorials')
      .doc(memorialId)
      .get()
      .then(memorialDoc => {
        this.setState({
          isPreApprovalRequired: memorialDoc.data().preApprovalRequired,
          personId: memorialDoc.data().person.id,
        })
        firestore
          .collection('People')
          .doc(memorialDoc.data().person.id)
          .get()
          .then(personDoc => {
            this.setState({
              isPrivate: personDoc.data().private,
              personId: memorialDoc.data().person.id,
              personName: personDoc.data().personName,
            })
          })
      })
  }

  handleMessagesPreApproval = e => {
    const isPreApprovalRequired = e.target.checked
    const { memorialId } = this.props
    const { firestore } = getFirebase()
    this.setState({
      isPreApprovalRequired,
    })
    firestore
      .collection('Memorials')
      .doc(memorialId)
      .set(
        {
          preApprovalRequired: isPreApprovalRequired,
        },
        { merge: true }
      )
  }

  handlePublicPrivateMemorial = e => {
    const isPrivate = e.target.checked
    const { personId } = this.state
    const { firestore } = getFirebase()
    this.setState({
      isPrivate,
    })
    firestore
      .collection('People')
      .doc(personId)
      .set(
        {
          private: isPrivate,
        },
        { merge: true }
      )
  }

  render() {
    const { isPreApprovalRequired, isPrivate, personId, personName } = this.state
    const { getStore } = this.props
    return (
      <section className="box ph2 w-100 pa3 pa4-ns mb3">
        <div className="flex flex-wrap w-100 center relative">
          <h2 className="ma0 mb4 mb4-ns fw5 f4 tc w-100 lh-title ph3 f5 f4-ns tc">
            Configurações do Memorial
          </h2>
          <div className="flex mb4">
            <div className="pr3">
              <Toggle checked={isPreApprovalRequired} onChange={this.handleMessagesPreApproval} />
            </div>
            <div className="tl">
              <div className="fw6 mb2">Aprovação de Mensagens</div>
              <p className="mv0 dark-gray f7 lh-copy pr2 w-60-ns">
                Caso habilitado, sempre que alguém publicar uma mensagem no livro de visitas, você
                receberá um e-mail solicitando sua aprovação antes que seja disponibilizada.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="pr3">
              <Toggle checked={isPrivate} onChange={this.handlePublicPrivateMemorial} />
            </div>
            <div className="tl">
              <div className="fw6 mb2">Memorial Privado</div>
              <p className="mv0 dark-gray f7 lh-copy pr2 w-60-ns">
                Caso habilitado, apenas as pessoas com as quais você compartilhar o link de seu
                memorial terão acesso a ele. O memorial não estará disponível para procura no site
                ou em sites de busca.
              </p>
              <div className="mt3">
                <small className="dark-gray mb1 db">Link do seu memorial:</small>
                <div className="f6">
                  {`https://immortalis.com.br/memorials/${isPrivate ? 'p/' : ''}${slugify(
                    personName
                  )}/${personId}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default MyMemorialSettings
