import React, { Component } from 'react'
import StepZilla from 'react-stepzilla'
import 'react-toggle/style.css'

import { getStringParams } from '../components/helpers/UIHelpers'
import { withFirebase } from '../components/firebase/context'
import Step3 from '../components/Memorials/Step3'
import Step4 from '../components/Memorials/Step4'
import MyMemorialSettings from '../components/Memorials/MyMemorialSettings'
import SEO from '../components/seo'

import { AuthUserContext } from '../components/Session'

class EditMemorial extends Component {
  constructor(props, context) {
    super(props, context)
    const memorialParam =
      typeof window !== 'undefined' ? getStringParams(window.location.href).id : null
    this.state = {
      memorialId: memorialParam,
      shouldShowMemorial: !!memorialParam,
    }
    const { memorialId } = this.state
    this.sampleStore = {
      personCity: '',
      personName: '',
      personBirthday: null,
      personDeathday: null,
      userName: '',
      userEmail: '',
      savedToCloud: false,
      memorialId,
    }
  }

  getStore() {
    return this.sampleStore
  }

  updateStore(update) {
    this.sampleStore = {
      ...this.sampleStore,
      ...update,
    }
  }

  render() {
    const { memorialId, shouldShowMemorial } = this.state
    const steps = [
      {
        name: 'Conteúdo',
        component: (
          <Step3
            getStore={() => this.getStore()}
            updateStore={u => {
              this.updateStore(u)
            }}
          />
        ),
      },
      {
        name: 'Visualização',
        component: (
          <Step4
            getStore={() => this.getStore()}
            updateStore={u => {
              this.updateStore(u)
            }}
          />
        ),
      },
    ]
    return (
      <>
        <SEO title="Gerenciamento de Memorial" />
        <AuthUserContext.Consumer>
          {authUser =>
            authUser && shouldShowMemorial ? (
              <section className="tc ph2 w-100 mb2 mb3-m mb4-l">
                <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8" />
                <div className="mw8-ns center ph2">
                  <h1 className="f5 f4-m f3-l mv4">Gerenciamento de Memorial</h1>
                  <MyMemorialSettings memorialId={memorialId} getStore={() => this.getStore()} />
                  <div className="step-progress mt4">
                    <StepZilla
                      steps={steps}
                      stepsNavigation={false}
                      preventEnterSubmission
                      dontValidate={false}
                      onStepChange={step => window.sessionStorage.setItem('step', step)}
                      startAtStep={1}
                    />
                  </div>
                </div>
              </section>
            ) : (
              <div />
            )
          }
        </AuthUserContext.Consumer>
      </>
    )
  }
}

export default withFirebase(EditMemorial)
